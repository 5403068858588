import axios from 'src/app-axios';
import Constants from 'src/constants';

export const invoices = {
    namespaced: true,
    state: {
        invoiceList: [],
    },
    getters: {
        getInvoices (state) {
            return state.invoiceList;
        },
    },
    mutations: {
        setInvoices (state, data) {
            state.invoiceList = data;
        },
    },
    actions: {
        async fetchInvoices ({ dispatch, commit }, payload) {
            const url = `api/projects/${payload.projectId}/invoices`;
            try {
                const details = await axios.get(url, payload.params);
                commit('setInvoices', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your grouped devices.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async updateInvoiceStatus ({ dispatch }, payload) {
            const url = `api/invoices/${payload.invoiceId}`;
            try {
              await axios.put(url, payload);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in update this job.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async dowloadDocment ({ dispatch }, payload) {
            const url = `api/documents/${payload.documentId}/download`;
            axios({
                url: url,
                method: 'GET',
                responseType: 'blob',
            }).then(response => {
                const blob = new Blob([response.data]);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = payload.fileName;
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(url);
            }).catch(exception => {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in downloading document file.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            });
        },
    },
};
