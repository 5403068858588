import axios from 'src/app-axios';
import Constants from 'src/constants';

export const featuresettings = {
    namespaced: true,
    state: {
        documentTypesList: [],
        selectedDocumentTypes: {},
        workerCategoryList: [],
        questionnaireList: [],
        },
    getters: {
      getDocumentTypesList (state) {
        return state.documentTypesList;
      },
      getSelectedDocumentTypes (state) {
        return state.selectedDocumentTypes;
      },
      getWorkerCategoryList (state) {
        return state.workerCategoryList;
      },
      getQuestionnaireList (state) {
        return state.questionnaireList;
      },
    },
    mutations: {
      setDocumentTypesList (state, data) {
        state.documentTypesList = data;
      },
      setSelectedDocumentTypes  (state, data) {
        state.selectedDocumentTypes = data;
      },
      setWorkerCategoryList (state, data) {
        state.workerCategoryList = data;
      },
      setAllQuestionnaireList (state, data) {
        state.questionnaireList = data;
      },
      clearSavedDocumentTypes (state) {
        state.selectedDocumentTypes = [];
      },
    },
    actions: {
      async createWorkerCategory ({ dispatch }, payload) {
        const url = 'api/worker-category';
        try {
          await axios.post(url, payload);
        } catch (exception) {
            dispatch('alert/onAlert', {
                message: 'Something went wrong in creating this worker category.',
                type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
            throw exception;
        }
      },
      async fetchDocumentTypes ({ dispatch, commit }) {
        const url = 'api/document-types';
        try {
            const response = await axios.get(url);
              commit('setDocumentTypesList', response.data);
        } catch (exception) {
            dispatch('alert/onAlert', {
                message: 'Something went wrong in getting document types.',
                type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
        }
      },
      async createDocumentType ({ dispatch }, payload) {
        const url = 'api/document-types';
        try {
          await axios.post(url, payload);
        } catch (exception) {
            dispatch('alert/onAlert', {
                message: 'Something went wrong in creating document type.',
                type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
            throw exception;
          }
      },
      async fetchWorkerCategoryList ({ dispatch, commit }) {
        const url = 'api/worker-category';
        try {
            const response = await axios.get(url);
              commit('setWorkerCategoryList', response.data);
        } catch (exception) {
            dispatch('alert/onAlert', {
                message: 'Something went wrong in getting worker category list.',
                type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
        }
      },
      async createQuestionnaire ({ dispatch }, payload) {
        const url = 'api/questionnaire';
        try {
          await axios.post(url, payload.questionnaire);
        } catch (exception) {
            dispatch('alert/onAlert', {
                message: 'Something went wrong in creating your questionnaire.',
                type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
            throw exception;
          }
      },
      async fetchAllQuestionnaire ({ dispatch, commit }) {
        const url = 'api/questionnaire';
        try {
            const response = await axios.get(url);
              commit('setAllQuestionnaireList', response.data);
        } catch (exception) {
            dispatch('alert/onAlert', {
                message: 'Something went wrong in getting this questionnaire list.',
                type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
        }
      },
      async saveSelectedDocTypes  ({ commit }, payload) {
        commit('setSelectedDocumentTypes', payload.selectedDocumentType);
      },
      clearSavedDocumentTypes ({ commit }) {
        commit('clearSavedDocumentTypes');
      },
      async removeQuestionnaire ({ dispatch }, payload) {
        const url = `api/questionnaire/${payload.QuestionnaireId}`;
        try {
            await axios.delete(url);
        } catch (exception) {
            dispatch('alert/onAlert', {
                message: 'Something went wrong in removing this questionnaire from list.',
                type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
        }
    },
    },
};
