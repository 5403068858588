import appAxios from 'src/app-axios';
import Constants from 'src/constants';

export const userprofile = {
    namespaced: true,
    state: {
        userProfile: {},
        countryCodes: {},
        organizationDetails: {},
        timeZones: {},
        currencyList: {},
    },
    getters: {
        getUserProfile (state) {
            return state.userProfile;
        },
        getCountryCodes (state) {
            return state.countryCodes;
        },
        getOrganizationDetails (state) {
            return state.organizationDetails;
        },
        getTimeZones (state) {
            return state.timeZones;
        },
        getCurrencyList (state) {
            return state.currencyList;
        },
    },
    mutations: {
        setUserProfile (state, data) {
            state.userProfile = data;
        },
        setCountryCodes (state, data) {
            state.countryCodes = data;
        },
        setOrganizationDetails (state, details) {
            state.organizationDetails = details;
        },
        setTimeZones (state, details) {
            state.timeZones = details;
        },
        setCurrencyList (state, data) {
            state.currencyList = data;
        },
    },
    actions: {
       async fetchUserProfile ({ dispatch, commit }) {
            const url = 'api/users';
            try {
                const details = await appAxios.get(url);
                commit('setUserProfile', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the user details.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchCountryCodes ({ dispatch, commit }) {
            const url = 'api/country-codes';
            try {
                const details = await appAxios.get(url);
                commit('setCountryCodes', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting country zip code.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async updateUserProfile ({ dispatch }, payload) {
            const url = 'api/users/update';
            try {
                await appAxios.put(url, payload.details);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating user profile.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchOrganizationDetails ({ dispatch, commit }) {
            const url = 'api/organizations';
            try {
                const details = await appAxios.get(url);
                commit('setOrganizationDetails', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your organization details.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async updateOrganizationDetails ({ dispatch }, payload) {
            const url = 'api/organizations/update';
            try {
                await appAxios.put(url, payload.editOrganizationDetails);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating organization details.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchTimeZones ({ dispatch, commit }) {
            const url = 'api/timezones';
            try {
                const details = await appAxios.get(url);
                commit('setTimeZones', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting TimeZone.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchCurrency ({ dispatch, commit }) {
            const url = 'api/currencies';
            try {
                const currency = await appAxios.get(url);
                commit('setCurrencyList', currency.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the currencies list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
    },
};
