import axios from 'src/app-axios';
import Constants from 'src/constants';

export const companies = {
    namespaced: true,
    state: {
        companiesList: [],
        companyTradesList: [],
        companyDetails: {},
        companiesFromOrgSearch: [],
        companyUsers: [],
    },
    getters: {
        getCompaniesList (state) {
            return state.companiesList;
        },
        getCompanyTradesList (state) {
            return state.companyTradesList;
        },
        getCompanyDetails (state) {
            return state.companyDetails;
        },
        getCompaniesFromOrgSearch (state) {
            return state.companiesFromOrgSearch;
        },
        getCompanyUsers (state) {
            return state.companyUsers;
        },
    },
    mutations: {
        setCompaniesList (state, data) {
            state.companiesList = data;
        },
        setCompanyTradesList (state, trades) {
            state.companyTradesList = trades;
        },
        setCompanyDetails (state, details) {
            state.companyDetails = details;
        },
        setCompaniesFromOrgSearch (state, companies) {
            state.companiesFromOrgSearch = companies;
        },
        clearAllCompaniesFromSearch (state) {
            state.companiesFromOrgSearch = [];
        },
        setCompanyUsers (state, companyusers) {
            state.companyUsers = companyusers;
        },
    },
    actions: {
        async fetchCompaniesList ({ dispatch, commit }) {
            const url = 'api/companies';
            try {
                const details = await axios.get(url);
                commit('setCompaniesList', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting companies list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchCompanyUsers ({ dispatch, commit }, payload) {
            const url = `api/app-users?companyId=${payload.companyId}&role=contractor`;
            try {
                const details = await axios.get(url);
                commit('setCompanyUsers', details.data.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting companies list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async createCompany ({ dispatch }, payload) {
            const url = 'api/companies';
            try {
              await axios.post(url, payload);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in creating this Company.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchCompanyTradesList ({ dispatch, commit }) {
            const url = 'api/companies/trades';
            try {
                const details = await axios.get(url);
                commit('setCompanyTradesList', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting company trades list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchCompanyDetails ({ dispatch, commit }, payload) {
            const url = `api/companies/${payload.companyId}`;
            try {
                const details = await axios.get(url);
                commit('setCompanyDetails', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the company details.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async updateCompanyDetails ({ dispatch }, payload) {
            const url = `api/companies/${payload.companyId}`;
            try {
                await axios.put(url, payload.updateDetails);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating the company details.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async deleteCompany ({ dispatch }, payload) {
            const url = `api/companies/${payload.companyId}`;
            try {
                await axios.delete(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in deleting this company.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchCompaniesFromOrgSearch ({ dispatch, commit }, payload) {
            const url = 'api/companies/search';
            try {
                const details = await axios.get(url, payload.params);
                commit('setCompaniesFromOrgSearch', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting companies list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        clearCompaniesFromOrgSearch ({ commit }) {
            commit('clearAllCompaniesFromSearch');
        },
        async importOrgCompanyToAccount ({ dispatch }, payload) {
            const url = 'api/user-companies';
            try {
              await axios.post(url, payload.companyName);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in importing this Company.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
    },
};
