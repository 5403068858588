import appAxios from 'src/app-axios';
import Constants from 'src/constants';

export const organization = {
    namespaced: true,
    state: {
        organizationsFromSearch: [],
    },
    getters: {
        getOrganizationFromSearch (state) {
            return state.organizationsFromSearch;
        },
    },
    mutations: {
        setOrganizationsFromSearch (state, data) {
            state.organizationsFromSearch = data;
        },
        clearAllOrganizationsFromSearch (state) {
            state.organizationsFromSearch = [];
        },
    },
    actions: {
        async fetchOrganizationFromSearch ({ dispatch, commit }, payload) {
            const url = 'api/organizations/search';
            try {
                const details = await appAxios.get(url, payload.params);
                commit('setOrganizationsFromSearch', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting organisations list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        clearOrganizationFromSearch ({ commit }) {
            commit('clearAllOrganizationsFromSearch');
        },
    },
};
