import axios from 'src/app-axios';
import Constants from 'src/constants';

export const settings = {
    namespaced: true,
    state: {
        settings: [],
    },
    getters: {
        getSettings (state) {
            return state.settings;
        },
    },
    actions: {
        async fetchSettings ({ dispatch, commit, state }) {
            const url = 'api/organization-settings';
            try {
                const settings = await axios.get(url);
                commit('setSettings', settings.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your settings.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async updateSettings ({ dispatch }, payload) {
            const url = 'api/organization-settings';
            try {
                await axios.put(url, payload);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating settings.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
    },
    mutations: {
        setSettings (state, settings) {
            state.settings = settings;
        },
    },
};
