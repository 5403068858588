import axios from 'src/app-axios';
import Constants from 'src/constants';

export const devices = {
  namespaced: true,
  state: {
    devicesList: [],
    kioskCheckIns: {},
  },
  getters: {
    getDevicesList (state) {
     return state.devicesList;
    },
    getKioskCheckIns (state) {
     return state.kioskCheckIns;
    },
  },
  mutations: {
    setDevicesList (state, data) {
        state.devicesList = data;
    },
    setKioskCheckIns (state, data) {
        state.kioskCheckIns = data;
    },
  },
  actions: {
    async fetchDevicesList ({ dispatch, commit }) {
      const url = 'api/kiosks';
      try {
        const details = await axios.get(url);
        commit('setDevicesList', details.data);
      } catch (exception) {
        dispatch('alert/onAlert', {
          message: 'Something went wrong in getting kiosks list.',
          type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
        throw exception;
      }
    },
    async createDevice ({ dispatch }, payload) {
        const url = 'api/kiosks';
        try {
         await axios.post(url, payload);
        } catch (exception) {
          dispatch('alert/onAlert', {
            message: exception.response.data.message,
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          throw exception;
        }
    },
    async deleteDevice ({ dispatch }, payload) {
        const url = `api/kiosks/${payload.id}`;
        try {
         await axios.delete(url);
        } catch (exception) {
          dispatch('alert/onAlert', {
            message: 'Something went wrong in deleting kiosk',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          throw exception;
        }
    },
    async updateDevice ({ dispatch }, payload) {
        const url = `api/kiosks/${payload.id}`;
        try {
         await axios.put(url, payload.data);
        } catch (exception) {
          dispatch('alert/onAlert', {
            message: exception.response.data.message,
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          throw exception;
        }
    },
    async fetchKioskCheckIns ({ dispatch, commit }, payload) {
      const url = `api/kiosks/${payload.kioskId}/records`;
      try {
     const response = await axios.get(url, payload.params);
     commit('setKioskCheckIns', response.data);
      } catch (exception) {
        dispatch('alert/onAlert', {
          message: 'Something went wrong in getting kiosk check-ins',
          type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
        throw exception;
      }
    },
  },
};
