import appAxios from 'src/app-axios';
import Constants from 'src/constants';

export const partners = {
    namespaced: true,
    state: {
        partnerAuthUri: {},
        isUserConfirmedForAuth: null,
        userHubs: [],
        projectsFromHubs: [],
        userSavedHubs: [],
    },
    getters: {
        getPartnerAuthUri (state) {
            return state.partnerAuthUri;
        },
        getUserInputOnConfirmAuth (state) {
            return state.isUserConfirmedForAuth;
        },
        getUserHubs (state) {
            return state.userHubs;
        },
        getProjectsFromHubs (state) {
            return state.projectsFromHubs;
        },
        getUserSavedHubs (state) {
            return state.userSavedHubs;
        },
    },
    mutations: {
        setPartnerAuthUri (state, data) {
            state.partnerAuthUri = data;
        },
        setUserInputOnConfirmAuth (state, inputVal) {
            state.isUserConfirmedForAuth = inputVal;
        },
        setUserHubs (state, hubs) {
            state.userHubs = hubs;
        },
        setProjectsFromHubs (state, data) {
            if (state.projectsFromHubs.some(item => item.hub_id === data.hub_id)) {
                return;
            }
            state.projectsFromHubs.push(data);
        },
        removeProjectsFromHubsArray (state) {
            state.projectsFromHubs = [];
        },
        setUserSavedHubs (state, hubs) {
            state.userSavedHubs = hubs;
        },
    },
    actions: {
        async fetchPartnerAuthUri ({ dispatch, commit }) {
            const url = 'api/partners/auth-uri';
            try {
                const details = await appAxios.get(url);
                commit('setPartnerAuthUri', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: exception.response.data.message,
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async passPartnerAuthDetails ({ dispatch }, details) {
            const url = 'api/partners/auth-token';
            try {
                await appAxios.post(url, details);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: exception.response.data.message,
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        storeUserInputOnConfirmAuth ({ commit }, input) {
            commit('setUserInputOnConfirmAuth', input);
        },
        async fetchCompaniesFromPartner ({ dispatch }) {
            const url = 'api/partners/companies/sync';
            try {
              await appAxios.post(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: exception.response.data.message,
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchProjectsFromPartner ({ dispatch }, payload) {
            const url = 'api/partners/projects/sync';
            try {
              await appAxios.post(url, payload.newProjects);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: exception.response.data.message,
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchBudgetsFromPartner ({ dispatch }, payload) {
            const url = `api/partners/projects/${payload.projectId}/budgets/sync`;
            try {
              await appAxios.post(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: exception.response.data.message,
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchProjectCompaniesFromPartner ({ dispatch }, payload) {
            const url = `api/partners/projects/${payload.projectId}/companies/sync`;
            try {
              await appAxios.post(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: exception.response.data.message,
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchProjectIssuesFromPartner ({ dispatch }, payload) {
            const url = `api/partners/projects/${payload.projectId}/issues/sync`;
            try {
              await appAxios.post(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: exception.response.data.message,
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchProjectContractsFromPartner ({ dispatch }, payload) {
            const url = `api/partners/projects/${payload.projectId}/contracts/sync`;
            try {
              await appAxios.post(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: exception.response.data.message,
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchProjectMainContractsFromPartner ({ dispatch }, payload) {
            const url = `api/partners/projects/${payload.projectId}/main-contracts/sync`;
            try {
              await appAxios.post(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: exception.response.data.message,
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchTrackingItemsFromPartner ({ dispatch }, payload) {
            const url = `api/partners/projects/${payload.projectId}/performance-tracking-items/sync`;
            try {
              await appAxios.post(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: exception.response.data.message,
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchTrackingItemInstancesFromPartner ({ dispatch }, payload) {
            const url = `api/partners/projects/${payload.projectId}/performance-tracking-instances/sync`;
            try {
              await appAxios.post(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: exception.response.data.message,
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchUserHubs ({ dispatch, commit }) {
            const url = 'api/partners/hubs/sync';
            try {
                const details = await appAxios.get(url);
                commit('setUserHubs', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: exception.response.data.message,
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchProjectsFromHubs ({ dispatch, commit, state }, payload) {
            if (state.projectsFromHubs.some(item => item.hub_id === payload.hubId)) {
                return;
            }
            const url = `api/partners/hubs/${payload.hubId}/projects`;
            try {
                const details = await appAxios.get(url);
                commit('setProjectsFromHubs', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: exception.response.data.message,
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        clearProjectsFromHubsArray ({ commit }) {
            commit('removeProjectsFromHubsArray');
        },
        async storeUserSelectedHubs ({ dispatch }, payload) {
            const url = 'api/partners/hubs';
            try {
              await appAxios.post(url, payload.hubs);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: exception.response.data.message,
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchUserSavedHubs ({ dispatch, commit }) {
            const url = 'api/partners/hubs';
            try {
                const details = await appAxios.get(url);
                commit('setUserSavedHubs', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: exception.response.data.message,
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
    },
};
