import appAxios from 'src/app-axios';
import Constants from 'src/constants';

export const attendance = {
    namespaced: true,
    state: {
        onsiteAttendance: {},
        offsiteAttendance: {},
        saveDetails: {},
    },
    getters: {
        getOnsiteAttendance (state) {
            return state.onsiteAttendance;
        },
        getOffsiteAttendance (state) {
            return state.offsiteAttendance;
        },
        getSavedDetails (state) {
            return state.saveDetails;
        },
    },
    mutations: {
        setOnsiteAttendance (state, details) {
            state.onsiteAttendance = details;
        },
        setOffsiteAttendance (state, details) {
            state.offsiteAttendance = details;
        },
        setSavePreDetails (state, data) {
            state.saveDetails = data;
        },
    },
    actions: {
        async fetchOnsiteAttendance ({ dispatch, commit }, payload) {
            const url = 'api/attendances/on-site';
            try {
                const details = await appAxios.get(url, payload);
                commit('setOnsiteAttendance', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting onsite attendance list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchOffsiteAttendance ({ dispatch, commit }, payload) {
            const url = 'api/attendances/off-site';
            try {
                const details = await appAxios.get(url, payload);
                commit('setOffsiteAttendance', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting offsite attendance list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async savePreDetails ({ commit }, payload) {
            commit('setSavePreDetails', payload);
        },
    },
};
