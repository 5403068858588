export default Object.freeze({
    ALERT_TYPE_ERROR: 'error',
    ALERT_TYPE_SUCCESS: 'success',
    ALERT_TYPE_INFO: 'info',
    MAX_NO_OF_QUESTIONS: 5,
    MODULE_DASHBOARD: 'dashboard',
    MODULE_SUPPORT: 'support',
    MODULE_USER_PROFILE: 'userProfile',
    MODULE_WIDGETS: 'widgets',
    MODULE_SETTINGS: 'settings',
    MODULE_COMPANIES: 'companies',
    MODULE_APP_USERS: 'appUsers',
    MODULE_RECORDS: 'records',
    MODULE_PROJECT_GATES: 'projectGate',
    MODULE_PROJECTS: 'projects',
    SUB_MODULE_BUDGETS: 'budgets',
    SUB_MODULE_CONTRACTS: 'contracts',
    SUB_MODULE_ISSUES: 'issues',
    SUB_MODULE_KIOSK: 'kiosk',
    SUB_MODULE_PROJECT_GATES: 'projectGate',
    SUB_MODULE_PERFORMANCE_TRACKING: 'performanceTracking',
    SUB_MODULE_MAIN_CONTRACTS: 'mainContracts',
    SUB_MODULE_SCHEDULE: 'schedules',
    SUB_MODULE_COMPANIES: 'companies',
    SUB_MODULE_MEMBERS: 'members',
    SUB_MODULE_ROLES: 'roles',
    SUB_MODULE_ACCOUNT_MEMBERS: 'members',
    SUB_MODULE_EMERGENCY_ALERTS: 'emergencyAlerts',
    SUB_MODULE_SUSPICIOUS_CHECKINS: 'suspiciousCheckins',
    SUB_MODULE_MANUAL_CHECKOUTS: 'manualCheckouts',
    SOCKET_ORGANIZATION_CHANNEL: 'private-organization-channel',
    SOCKET_SEND_ALERT_MESSAGE: 'sent.alert.message',
    SOCKET_RECEIVE_ALERT_RESPONSE: 'sent.alert.response',
    SOCKET_COMPANY_SYNC_COMPLETED: 'company.sync.completed',
    SOCKET_PROJECT_SYNC_COMPLETED: 'project.sync.completed',
    SOCKET_BUDGET_SYNC_COMPLETED: 'budget.sync.completed',
    SOCKET_APP_USER_GROUP_UPDATED: 'app.user.group.updated',
    SOCKET_TICKET_COMMENT_CREATED: 'support.team.created.comment',
    SOCKET_TIME_SHEET_UPLOAD_SUCCESS: 'timesheet.upload.successful',
    SOCKET_APP_USER_REQUEST_ACCEPT: 'app.user.organization.request.accept',
    MODULE_MEMBERS: 'members',
    MODULE_ATTENDANCE: 'attendance',
    SUB_MODULE_JOBS: 'jobs',
    SUB_MODULE_JOBS_SCHEDULE: 'schedules',
    SUB_MODULE_JOBS_CASHFLOW: 'cashflow',
    SUB_MODULE_JOBS_INVOICE: 'invoice',
});
