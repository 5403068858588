import axios from 'src/app-axios';
import Constants from 'src/constants';

export const cashflow = {
  namespaced: true,
  state: {
    cashFlows: [],
  },
  getters: {
    getCashFlows (state) {
      return state.cashFlows;
    },
  },
  mutations: {
    setCashFlows (state, data) {
      state.cashFlows = data;
    },
    clearCashFlow (state) {
      state.cashFlows = [];
  },
  },
  actions: {
    async fetchCashFlows ({ commit, dispatch }, payload) {
      const url = `api/projects/${payload.projectId}/cash-flows`;
      try {
        const response = await axios.get(url);
        commit('setCashFlows', response.data);
      } catch (exception) {
        dispatch(
          'alert/onAlert',
          {
            message: 'Something went wrong in getting the cash flow.',
            type: Constants.ALERT_TYPE_ERROR,
          },
          { root: true },
        );
      }
    },
    async deleteCashFlow ({ dispatch }, payload) {
      const url = `api/cash-flows/${payload.id}`;
      try {
        await axios.delete(url, payload);
      } catch (exception) {
        dispatch(
          'alert/onAlert',
          {
            message: 'Something went wrong in deleting this record.',
            type: Constants.ALERT_TYPE_ERROR,
          },
          { root: true },
        );
        throw exception;
      }
    },
    async updateCashFlow ({ dispatch }, payload) {
      const url = `api/cash-flows/${payload.id}`;
      try {
        await axios.put(url, payload.details);
      } catch (exception) {
        dispatch(
          'alert/onAlert',
          {
            message: 'Something went wrong in updating this record.',
            type: Constants.ALERT_TYPE_ERROR,
          },
          { root: true },
        );
        throw exception;
      }
    },
    clearCashFlows ({ commit }) {
      commit('clearCashFlow');
  },
    async addCashFlow ({ dispatch }, payload) {
      const url = `api/projects/${payload.projectId}/cash-flows`;
      try {
        await axios.post(url, payload.details);
      } catch (exception) {
          dispatch('alert/onAlert', {
              message: 'Something went wrong in adding cash flow.',
              type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          throw exception;
      }
    },
  },
};
