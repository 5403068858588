import axios from 'src/app-axios';
import Constants from 'src/constants';

export const support = {
    namespaced: true,
    state: {
      finalIssueList: [],
      supportTicketChats: [],
      ticketContent: {},
    },
    getters: {
      getFinalIssueList (state) {
        return state.finalIssueList;
      },
      getTicketContent (state) {
        return state.ticketContent;
      },
      getSupportTicketChats (state) {
        return state.supportTicketChats;
      },
    },
    mutations: {
      setFinalIssueList (state, data) {
        state.finalIssueList = data;
      },
      clearIssueArray (state) {
        state.finalIssueList = [];
      },
      setTicketContent (state, data) {
        state.ticketContent = data;
      },
      clearOffTicketContent (state) {
        state.ticketContent = {};
      },
      setSupportTicketChats (state, chats) {
        state.supportTicketChats = chats;
      },
    },
    actions: {
      async createIssueTicket ({ dispatch }, payload) {
        const url = 'api/support/tickets';
        try {
          await axios.post(url, payload);
        } catch (exception) {
            dispatch('alert/onAlert', {
                message: 'Something went wrong in creating your issue ticket.',
                type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
            throw exception;
        }
      },
      async fetchSupportTickets ({ dispatch, commit }, { params }) {
        const url = 'api/support/tickets';
        try {
            const response = await axios.get(url, { params: params });
              commit('setFinalIssueList', response.data);
        } catch (exception) {
            dispatch('alert/onAlert', {
                message: 'Something went wrong in getting your support tickets.',
                type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
        }
      },
      async deleteIssueTicket ({ dispatch }, ticketId) {
        const url = `api/support/tickets/${ticketId}`;
        try {
            await axios.delete(url);
        } catch (exception) {
            dispatch('alert/onAlert', {
                message: 'Something went wrong in deleting this ticket.',
                type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
            throw exception;
        }
      },
      clearIssueList ({ commit }) {
        commit('clearIssueArray');
      },
      async fetchSupportTicketChats ({ dispatch, commit }, payload) {
        const url = `api/support/tickets/${payload.ticketId}/comments`;
        try {
          const response = await axios.get(url);
          commit('setSupportTicketChats', response.data);
        } catch (exception) {
            dispatch('alert/onAlert', {
              message: 'Something went wrong in getting this ticket chats.',
              type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
        }
      },
      async sendSupportMessage ({ dispatch }, payload) {
        const url = `api/support/tickets/${payload.ticketId}/comments`;
        try {
          await axios.post(url, payload.messageContent);
        } catch (exception) {
            dispatch('alert/onAlert', {
                message: 'Something went wrong in sending this message.',
                type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
            throw exception;
        }
      },
      async fetchTicketContent ({ dispatch, commit }, payload) {
        const url = `api/support/tickets/${payload.ticketId}`;
        try {
            const details = await axios.get(url);
            commit('setTicketContent', details.data);
        } catch (exception) {
            dispatch('alert/onAlert', {
                message: 'Something went wrong in getting the ticket details.',
                type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
        }
      },
      clearTicketContent ({ commit }) {
        commit('clearOffTicketContent');
      },
    },
};
