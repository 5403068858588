import Vue from 'vue';
import Vuex from 'vuex';
import axios from '../app-axios';

// Module imports
import { alert } from './modules/alert.module';
import { orgsettings } from './modules/orgsettings.module';
import { support } from './modules/support.module';
import { notifications } from './modules/notifications.module';
import { userprofile } from './modules/userprofile.module';
import { accountsettings } from './modules/accountsettings.module';
import { users } from './modules/users.module';
import { widgets } from './modules/widgets.module';
import { appusers } from './modules/appusers.module';
import { projectgates } from './modules/projectgates.module';
import { companies } from './modules/companies.module';
import { featuresettings } from './modules/featuresettings.module';
import { records } from './modules/records.module';
import { dashboard } from './modules/dashboard.module';
import { workorders } from './modules/workorders.module';
import { partners } from './modules/partners.module';
import { projects } from './modules/projects.module';
import { members } from './modules/members.module';
import { organization } from './modules/organization.module';
import { attendance } from './modules/attendance.module';
import { settings } from './modules/settings.module';
import { devices } from './modules/devices.module';
import { jobs } from './modules/jobs.module';
import { invoices } from './modules/invoices.module';
import { cashflow } from './modules/cashflow.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: require('@/assets/sidebar-3.jpg'),
    drawer: null,
    user: null,
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload;
    },
    setUserData (state, userData) {
      state.user = userData;
      axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`;
    },

    clearUserData () {
      localStorage.removeItem('scp-user');
      localStorage.removeItem('ds-user');
      location.reload();
    },
  },
  actions: {
    login ({ commit }, credentials) {
      return axios
        .post('api/login', credentials)
        .then(({ data }) => {
          commit('setUserData', data);
        });
    },

    logout ({ commit }) {
      commit('clearUserData');
    },
  },

  getters: {
    isLogged: state => !!state.user,
    getUser (state) {
      return state.user;
    },
  },

  modules: {
    alert,
    orgsettings,
    support,
    notifications,
    userprofile,
    accountsettings,
    users,
    widgets,
    appusers,
    projectgates,
    companies,
    featuresettings,
    records,
    dashboard,
    workorders,
    partners,
    projects,
    members,
    organization,
    attendance,
    settings,
    devices,
    jobs,
    invoices,
    cashflow,
  },
});
